































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class TableDialog extends Vue {
    @Prop({ default: 'div', type: String }) tag!: string;
    @Prop({ required: true, type: String }) title!: string;
    @Prop({ type: String }) classButton!: string;

    dialogVisible = false;

    openDialog() {
        this.dialogVisible = !this.dialogVisible;
    }
}
