
















































































































































































































































































































































































































































































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import { customerModule } from '@/store/modules/customer';
import FilterBroker from '@/components/Broker/Filter.vue';
import ExpandTableMixin from '@/mixins/expand-table';
import { agentModule } from '@/store/modules/agent';
import ButtonLink from './ButtonLink.vue';
import TableCustomer from './TableCustomer.vue';
import EmptyChild from './EmptyChild.vue';
import TableDialog from './TableDialog.vue';
import FormCustomer from '@/components/FormCustomer.vue';
import { Filter } from '@/interfaces/filter';
import { count } from '@/filters/count';
import ResizeMixin from '@/mixins/size';
import {
    CountKeys,
    Customer as CustomerInterface
} from '@/interfaces/customer';

@Component({
    components: {
        FilterBroker,
        ButtonLink,
        TableCustomer,
        EmptyChild,
        TableDialog,
        FormCustomer
    },
    filters: { count }
})
export default class Customer extends Mixins(ExpandTableMixin, ResizeMixin) {
    hideStep = false;
    searchAnimation = false;
    customerAnimation = false;
    dialogVisible = false;
    form: Partial<CustomerInterface> = {};
    dialogVisibleBanner = true;
    mobileProspecto = true;
    resize() {
        this.mobileProspecto = window.innerWidth <= 768;
    }

    get tableData() {
        return customerModule.customerRequests;
    }

    get disaffiliated() {
        return agentModule.disaffiliated;
    }

    countByKey(value: CustomerInterface, key: keyof CountKeys) {
        return count(value, key) === 0 ? false : true;
    }

    mounted() {
        customerModule.index();
        this.resize();
    }

    filterData(filter: Filter) {
        customerModule.setFilter(filter);
        customerModule.index();
    }

    open(customer: CustomerInterface) {
        this.form = { parentId: customer._id, type: 'secondary' };
        this.dialogVisible = true;
    }

    async remove(id: string) {
        await customerModule.remove(id);
        this.$notify.success({
            title: 'Prospecto eliminado!',
            message: 'Se ha eliminado al prospecto con éxito'
        });
        await customerModule.index();
    }

    changePage(page: number) {
        this.filterData({ page });
    }

    async store() {
        try {
            await customerModule.storeAssociated(this.form);
            await customerModule.index();
            this.dialogVisible = false;
            this.$notify.success({
                title: 'Prospecto asociado registrado!',
                message: 'Se ha agregado al prospecto asociado con éxito'
            });
        } catch (error) {
            this.$notify.error({
                title: 'Error',
                message:
                    typeof error.mensaje === 'string'
                        ? error.mensaje
                        : 'Hemos tenido algunos inconvenientes'
            });
        }
    }
}
