























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Route } from 'vue-router';

@Component
export default class ButtonLink extends Vue {
    @Prop({ required: true }) to!: Route;
    @Prop({ default: 'bottom-end', type: String }) placement!: string;
    @Prop({ type: String }) content!: string;
    @Prop({ type: String }) emptyText!: string;
    @Prop({ default: 0 }) count!: number;
    @Prop({ type: Boolean, default: false }) hideCount!: boolean;
    @Prop({ required: true, type: String }) icon!: string;

    get text() {
        return this.count < 1
            ? this.emptyText
            : this.content
            ? this.content.replace('[count]', this.count.toString())
            : '';
    }
}
