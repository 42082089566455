
































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Customer } from '@/interfaces/customer';
import { typeDoc } from '@/filters/type-doc';

@Component({
    filters: { typeDoc }
})
export default class TableCustomer extends Vue {
    @Prop({ required: true }) customer!: Customer;
}
