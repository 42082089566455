

















import { Component, Vue, Prop } from 'vue-property-decorator';
import { Customer } from '@/interfaces/customer';
import TableCustomer from './TableCustomer.vue';

@Component({
    components: { TableCustomer }
})
export default class EmptyChild extends Vue {
    @Prop({ required: true }) customer!: Customer;
    @Prop({ required: true }) child!: Customer;
    @Prop({ default: 'div' }) tag!: string;

    open() {
        this.$emit('open', this.customer);
    }
}
